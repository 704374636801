@import 'assets/scss/bootstrap-material.scss';
@import 'assets/scss/app-material.scss';
@import 'assets/scss/icons.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import '~ngx-toastr/toastr-bs4-alert';

mat-form-field {
	width: 100%;
}
.border-dashed{
	border-style: dashed !important;
	border-width: 0;
}
.background-sp {
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
}
