//
// footables.scss
//

@font-face {
    font-family: 'footable';
    src: url("/assets/fonts/footable.eot");
    src: url("/assets/fonts/footable.eot?#iefix") format('embedded-opentype'), url("/assets/fonts/footable.woff") format('woff'), url("/assets/fonts/footable.ttf") format('truetype'), url("/assets/fonts/footable.svg#footable") format('svg');
    font-weight: normal;
    font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
    @font-face {
        font-family: 'footable';
        src: url("/assets/fonts/footable.svg#footable") format('svg');
        font-weight: normal;
        font-style: normal;
    }
}

.footable-odd {
    background-color: $table-bg;
}

.footable-row-detail,
.footable-detail-show {
    background-color: $table-accent-bg;
}

.footable-pagination {
    li {
        margin-left: 5px;
        float: left;

        a {
            padding: $pagination-padding-y $pagination-padding-x;
            margin-left: -$pagination-border-width;
            line-height: $pagination-line-height;
            color: $pagination-color;
            background-color: $pagination-bg;
            display: block;
            border: $pagination-border-width solid $pagination-border-color;
            border-radius: $border-radius;

            &:hover {
                z-index: 2;
                color: $pagination-hover-color;
                text-decoration: none;
                background-color: $pagination-hover-bg;
                border-color: $pagination-hover-border-color;
            }
        }

        &.active {
            a {
                color: $white;
                background-color: $primary;
                border-color: $primary;
            }
        }
    }
}

.footable>thead>tr>th>span.footable-sort-indicator {
    float: right;
}

.footable {
    width: 100%;
}

.footable.breakpoint>tbody>tr.footable-detail-show>td {
    border-bottom: none;
}

.footable.breakpoint>tbody>tr.footable-detail-show>td>span.footable-toggle:before {
    content: "\e001";
}

.footable.breakpoint>tbody>tr:hover:not(.footable-row-detail) {
    cursor: pointer;
}

.footable.breakpoint>tbody>tr>td.footable-cell-detail {
    border-top: none;
}

.footable.breakpoint>tbody>tr>td>span.footable-toggle {
    display: inline-block;
    font-family: 'footable';
    padding-right: 5px;
    font-size: 14px;
}

.footable.breakpoint>tbody>tr>td>span.footable-toggle:before {
    content: "\e000";
}

.footable.breakpoint.toggle-circle>tbody>tr.footable-detail-show>td>span.footable-toggle:before {
    content: "\e005";
}

.footable.breakpoint.toggle-circle>tbody>tr>td>span.footable-toggle:before {
    content: "\e004";
}

.footable.breakpoint.toggle-circle-filled>tbody>tr.footable-detail-show>td>span.footable-toggle:before {
    content: "\e003";
}

.footable.breakpoint.toggle-circle-filled>tbody>tr>td>span.footable-toggle:before {
    content: "\e002";
}

.footable.breakpoint.toggle-square>tbody>tr.footable-detail-show>td>span.footable-toggle:before {
    content: "\e007";
}

.footable.breakpoint.toggle-square>tbody>tr>td>span.footable-toggle:before {
    content: "\e006";
}

.footable.breakpoint.toggle-square-filled>tbody>tr.footable-detail-show>td>span.footable-toggle:before {
    content: "\e009";
}

.footable.breakpoint.toggle-square-filled>tbody>tr>td>span.footable-toggle:before {
    content: "\e008";
}

.footable.breakpoint.toggle-arrow>tbody>tr.footable-detail-show>td>span.footable-toggle:before {
    content: "\e00f";
}

.footable.breakpoint.toggle-arrow>tbody>tr>td>span.footable-toggle:before {
    content: "\e011";
}

.footable.breakpoint.toggle-arrow-small>tbody>tr.footable-detail-show>td>span.footable-toggle:before {
    content: "\e013";
}

.footable.breakpoint.toggle-arrow-small>tbody>tr>td>span.footable-toggle:before {
    content: "\e015";
}

.footable.breakpoint.toggle-arrow-circle>tbody>tr.footable-detail-show>td>span.footable-toggle:before {
    content: "\e01b";
}

.footable.breakpoint.toggle-arrow-circle>tbody>tr>td>span.footable-toggle:before {
    content: "\e01d";
}

.footable.breakpoint.toggle-arrow-circle-filled>tbody>tr.footable-detail-show>td>span.footable-toggle:before {
    content: "\e00b";
}

.footable.breakpoint.toggle-arrow-circle-filled>tbody>tr>td>span.footable-toggle:before {
    content: "\e00d";
}

.footable.breakpoint.toggle-arrow-tiny>tbody>tr.footable-detail-show>td>span.footable-toggle:before {
    content: "\e01f";
}

.footable.breakpoint.toggle-arrow-tiny>tbody>tr>td>span.footable-toggle:before {
    content: "\e021";
}

.footable.breakpoint.toggle-arrow-alt>tbody>tr.footable-detail-show>td>span.footable-toggle:before {
    content: "\e017";
}

.footable.breakpoint.toggle-arrow-alt>tbody>tr>td>span.footable-toggle:before {
    content: "\e019";
}

.footable.breakpoint.toggle-medium>tbody>tr>td>span.footable-toggle {
    font-size: 18px;
}

.footable.breakpoint.toggle-large>tbody>tr>td>span.footable-toggle {
    font-size: 24px;
}

.footable>thead>tr>th {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: -moz-none;
    -ms-user-select: none;
    user-select: none;
}

.footable>thead>tr>th.footable-sortable:hover {
    cursor: pointer;
}

.footable>thead>tr>th.footable-sorted>span.footable-sort-indicator:before {
    content: "\e012";
}

.footable>thead>tr>th.footable-sorted-desc>span.footable-sort-indicator:before {
    content: "\e013";
}

.footable>thead>tr>th>span.footable-sort-indicator {
    display: inline-block;
    font-family: 'footable';
    padding-left: 5px;
    opacity: 0.3;
}

.footable>thead>tr>th>span.footable-sort-indicator:before {
    content: "\e022";
}

.footable>tfoot .pagination {
    margin: 0;
}

.footable.no-paging .hide-if-no-paging {
    display: none;
}

.footable-row-detail-inner {
    display: table;
}

.footable-row-detail-row {
    display: table-row;
    line-height: 1.5em;
}

.footable-row-detail-group {
    display: block;
    line-height: 2em;
    font-size: 1.2em;
    font-weight: $font-weight-bold;
}

.footable-row-detail-name {
    display: table-cell;
    font-weight: $font-weight-bold;
    padding-right: 0.5em;
}

.footable-row-detail-value {
    display: table-cell;
}