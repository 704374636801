//
// x-editable.scss
//

.editable-clear-x {
    background: url("/assets/images/clear.png") center center no-repeat;
}

.editableform-loading {
    background: url("/assets/images/loading.gif") center center no-repeat;
}

.editable-checklist label {
    display: block;
}